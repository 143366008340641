/* Service.css */
/* first - img */
.banner-service {
  position: relative;
  background-color: #e9e9e9;
  overflow: hidden;
}

.banner-service::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)),
    url("../assets/img/petroleum.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.banner-service:hover::before {
  opacity: 0.9;
}
.banner-service:hover h3,
.banner-service:hover p {
  color: #fff;
}
/* second - img */
.banner-service1 {
  position: relative;
  background-color: #e9e9e9;
  overflow: hidden;
}

.banner-service1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)),
    url("../assets/img/chemical.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.banner-service1:hover::before {
  opacity: 0.9;
}
.banner-service1:hover h3,
.banner-service1:hover p {
  color: #fff;
}
/* third - img */
.banner-service2 {
  position: relative;
  background-color: #e9e9e9;
  overflow: hidden;
}

.banner-service2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)),
    url("../assets/img/petrochemical.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.banner-service2:hover::before {
  opacity: 0.9;
}
.banner-service2:hover h3,
.banner-service2:hover p {
  color: #fff;
}
/* four - img */
.banner-service3 {
  position: relative;
  background-color: #e9e9e9;
  overflow: hidden;
}

.banner-service3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)),
    url("../assets/img/metal-menarals.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.banner-service3:hover::before {
  opacity: 0.9;
}
.banner-service3:hover h3,
.banner-service3:hover p {
  color: #fff;
}
/* five - img */
.banner-service4 {
  position: relative;
  background-color: #e9e9e9;
  overflow: hidden;
}

.banner-service4::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)),
    url("../assets/img/food.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.banner-service4:hover::before {
  opacity: 0.9;
}
.banner-service4:hover h3,
.banner-service4:hover p {
  color: #fff;
}
.banner-service-content {
  position: relative;
  z-index: 1;
}
.banner-service-content h3 {
  color: #04128a;
  transition: color 0.3s ease;
}
.banner-service-content p {
  color: #000;
  transition: color 0.3s ease;
}
