/* Homepage banner image */
.video-container {
  width: 100% !important;
  height: 100vh !important;
}
.video-container video {
  object-fit: cover !important;
}
.video-container2 video {
  object-fit: fill !important;
  /* border-radius: 15px; */
}
.video-container2 {
  width: 100% !important;
  height: 100% !important;
}
.video-container3 {
  width: 100% !important;
  height: 100% !important;
}
.button-appointment {
  background-color: #04128a;
  color: #fff;
}
.button-appointment:hover {
  background-color: #f2bd00;
  color: #000;
}
.image-zoom:hover {
  -ms-transform: scale(1.06);
  -webkit-transform: scale(1.06);
  transform: scale(1.06);
}
/* our industries css */
input[type="range"] {
  -webkit-appearance: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 300px;
  height: 2px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 4px;
  width: 20%;
  border-radius: 50px;
  background: #cecece;
  margin-top: -1px;
  cursor: e-resize;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #e9e9e9;
  height: 2px;
}
.carousel-container-with-scrollbar1 {
  padding-bottom: 20px;
  overflow: hidden !important;
}
.custom-slider1 {
  width: 100%;
}
.custom-slider__input1 {
  width: 100%;
}
.slider-image-item1 {
  margin: 0px 45px 0px 0px;
}
.react-multi-carousel-track {
  display: flex;
}
.custom-dot-list-style {
  margin-top: 10px;
  text-align: center;
}
.custom-dot-list-style button {
  background-color: #ccc;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin: 0 5px;
  cursor: pointer;
}
.custom-dot-list-style button.active {
  background-color: #333;
}
